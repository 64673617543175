import './mobileSlider'

const tabs = document.querySelectorAll('.details__list')
const tabContents = document.querySelectorAll('[data-tab-content]')
const body = document.body
const toggleButton = document.querySelector('.toggle')
const preferenceQuery = window.matchMedia('(prefers-color-scheme: dark)')

tabs.forEach((tab) => {
	tab.addEventListener('click', () => {
		const target = document.querySelector(tab.dataset.tabTarget)
		tabContents.forEach((tabContent) => tabContent.classList.remove('active'))

		tabs.forEach((tab) => tab.classList.remove('active'))
		tab.classList.add('active')
		target.classList.add('active')
	})
})

const addDarkMode = () => {
	body.classList.add('dark-mode')
}

const addLightMode = () => {
	body.classList.remove('dark-mode')
}

const toggleTheme = () =>
	!body.classList.contains('dark-mode') ? addDarkMode() : addLightMode()

const checkPreference = () =>
	preferenceQuery.matches ? addDarkMode() : addLightMode()

toggleButton.addEventListener('click', toggleTheme)
preferenceQuery.addEventListener('change', checkPreference)

// window.addEventListener("DOMContentLoaded", checkPreference);
;(() => checkPreference())()
