const slider = document.querySelector('.mobile-details__container'),
	slides = document.querySelectorAll('.mobile-details__wrapper.slide')

let isDragging = false,
	startPos = 0,
	currentTranslate = 0,
	prevTranslate = 0,
	animationID = 0,
	currentIndex = 0

slides.forEach((slide, index) => {
	slide.addEventListener('touchstart', touchStart(index))
	slide.addEventListener('touchend', touchEnd)
	slide.addEventListener('touchmove', touchMove)
})

//disable context menu
// window.oncontextmenu = function (event) {
// 	event.preventDefault()
// 	event.stopPropagation()
// 	return false
// }

function touchStart(index) {
	return function (event) {
		currentIndex = index
		startPos = getPositionX(event)
		isDragging = true
		animationID = requestAnimationFrame(animation)
	}
}

function touchEnd() {
	isDragging = false
	cancelAnimationFrame(animationID)

	const movedBy = currentTranslate - prevTranslate
	if (movedBy < -100 && currentIndex < slides.length - 1) {
		currentIndex += 1
	}

	if (movedBy > 100 && currentIndex > 0) {
		currentIndex -= 1
	}

	setPositionByIndex()
}

function touchMove(event) {
	if (isDragging) {
		const currentPosition = getPositionX(event)
		currentTranslate = prevTranslate + currentPosition - startPos
	}
}

function getPositionX(event) {
	return event.touches[0].clientX
}

function animation() {
	setSliderPosition()
	if (isDragging) requestAnimationFrame(animation)
}

function setSliderPosition() {
	slider.style.transform = `translateX(${currentIndex}px)`
}

function setPositionByIndex() {
	currentTranslate = currentIndex * -window.innerWidth
	prevTranslate = currentTranslate
	setSliderPosition()
}
